import 'react-toastify/dist/ReactToastify.css'

import GlobalStyle from 'src/styles/global'
import PropTypes from 'prop-types'
import React from 'react'
import theme from 'src/styles/theme'
import { ThemeProvider } from 'styled-components'
import { ToastContainer } from 'react-toastify'

const Layout = ({ children }) => (
  <ThemeProvider theme={theme}>
    <GlobalStyle />
    <main>{children}</main>
    <ToastContainer position='bottom-center' />
  </ThemeProvider>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
